/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-05-24",
    versionChannel: "nightly",
    buildDate: "2023-05-24T00:22:28.395Z",
    commitHash: "358beabf5a49807bafd0122a73ff62514ceb4b9a",
};
